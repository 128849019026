import './App.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Sidebar from './components/sidebar/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from "react-redux";
import Events from './components/events/events';
import DeletePoint from './components/points/deletePoint';
import Points1 from './components/points/point';
import AddUser from './components/points/add';
import Point from './components/points/point';
import EditPoint1 from './components/points/EditPoint';
import FrontPage from './components/frontPage/frontPage';
import Register from './components/register/register';
import EditEvent from './components/events/EditEvent';

const App = (props) => {
  console.log("App props", props)
  return (
    <>
      <ToastContainer position="bottom-left" theme="colored" style={{ zIndex: '999999' }} />
      <Router>
        {console.log("props",props?.logged_in)}
        {props?.logged_in === true && <Sidebar />}
        {/* <Sidebar/> */}
        <Routes>
          {props?.logged_in === false ?
            <>

              {/* <Route exact path="/" element={<Login />} />
              <Route exact path="*" element={<Login />} /> */}
              {/* <Route path="/" element={<FrontPage />} /> */}
              <Route path="/" element={<Register />} />
              <Route path="/*" element={<Register />} />
            </>

            : <>
              <Route exact path="/" element={<Events />} />
              <Route path="/point" element={<Point />} />
              <Route exact path="/points" component={Points1} />
              <Route path="/add-point" element={<AddUser />} />
              <Route path="/edit-point/:idParams" element={<EditPoint1 />} />
              <Route path="/edit-event/:idParams" element={ <EditEvent/>} />
              <Route path="/delete/:idParams" element={<DeletePoint />} />
            </>
          }
        </Routes>
      </Router>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setSideBar: (data) => dispatch(setSideBar(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
