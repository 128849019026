import React, {useState, useEffect} from 'react';
import './events.scss';
import axios from '../../axios.js'
import Search from "../Search/SearchInputHeader.js";
import ReactPaginate from "react-paginate";
import FeaturesModal from "./pointFeaturesModal.js";
import DeletePointFeatureModal from "./deletePointFeaturesModal.js";
import { connect } from "react-redux";
import { TableStyleEvents } from '../events/eventsStyle.js';
import { ModalStyle, ModalHandleButtons, ModalClose, ModalForm, ModalMainButtons, ModalButtonsPosition, ModalWholePage, ModalButtons } from "../styles/ModalStyle"
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
const ListingPointFeatures = (props) => {
  console.log("pointId",props)
    const [features, setFeatures] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [dataToModal, setDataToModal] = useState(null);
    const [deleteId, setDeleteId] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const productsPerPage = 8;
    const pagesVisited = pageNumber * productsPerPage;
    const pageCount = Math.ceil(filtered?.length / productsPerPage);

    const changePage = ({selected}) => {
        setPageNumber(selected);
    };

    const getData = () => {
      axios.get(`point-features/all/${props?.pointId}`).then(
        res => {
            setFeatures(res?.data);
            setFiltered(res?.data)
            setIsLoading(false)
        }
      ).catch(err => { console.log('Errori', err) });
    }

   
    useEffect(() => {
      getData();

    }, []);
  

    const handleModalDelete = (data) => {
      setIsOpenDelete(true);
      setDeleteId(data);
    };

    const handleModalAdd = () => {
      setIsOpen(true);
      setModalType("add");
    };

    return (
      <>
      {isOpen === true && <FeaturesModal pointId={Number(props?.pointId)} getData={()=>getData()} type={modalType} cat_id={props?.category_id} data={dataToModal} isOpen={isOpen} handleModalClose={() => { setIsOpen(false); setDataToModal(null) }} user_id={props?.user_id} />}
      {isOpenDelete === true && <DeletePointFeatureModal isOpen={isOpenDelete} getData={()=>getData()} handleModalClose={() => { setIsOpenDelete(false)}} user_id={props?.user_id} eventData={deleteId} />}
        <div className='mt-5 text-left'>
        <div className="col-md-12 mb-3 mt-4">
              <h2>Point Features</h2>
              <div className="row">
        <div className="col-md-12">
            <TableStyleEvents tableEditPoint className="table-responsive"  >
                    <Table className="custom-table table table-hover" >
                      <Thead>
                        
                        <Tr>
                          <Th className="table-header col-md-3">Point</Th>
                          <Th className="table-header col-md-3">Feature</Th>
                          <Th className="table-header col-md-2">Delete</Th>
                          <Th className="table-header col-md-2">
                            <ModalButtonsPosition className=''>
                              <ModalMainButtons onClick={handleModalAdd}><i className="fas fa-plus" /> Add Feature</ModalMainButtons>
                            </ModalButtonsPosition>
                          </Th>

                        </Tr>
                      </Thead>
                      <Tbody>
                      {
                    isLoading ? "Loading" :
                       filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map(filtered => {
                       
                    return (
                      <Tr>
                                <Td>{filtered?.point?.name}</Td>
                                <Td>{filtered?.listing_feature?.name}</Td>
                               
                                <Td><i className='fa fa-trash text-danger' onClick={() => handleModalDelete(filtered)}></i></Td>
                                <Td></Td>
                        </Tr>
                    
                    )
                })}
        
                      </Tbody>
                    </Table>
                  </TableStyleEvents>
                  </div>
                  </div>
                  </div>
            <div className="row mt-5">
                <div className="col-12">
                    {(features.length >= productsPerPage) ?
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        /> : ''
                    }

                </div>
            </div>
        </div>
      </>

    )

}
const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebarCollapse: state?.data?.sidebar,
    user_id: state?.data?.user_id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingPointFeatures);