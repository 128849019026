import React, { useEffect, useState } from "react";
import axios from '../../axios';
import ModalPortal from "../../portal/ModalPortal";
import { ModalStyle, ModalHandleButtons, ModalClose, ModalForm, ModalMainButtons, ModalButtonsPosition, ModalWholePage, ModalButtons } from "../styles/ModalStyle"
import { FaTimes } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";

const PointFeaturesModal = (props) => {
    console.log("ppp", props)
    const [isEdit, setEdit] = useState(false);
    const [isdelete, setDelete] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [modalIsOpen, setIsOpen] = React.useState();
    const [features, setFeatures] = useState();

    const [addList, setAddList] = useState({
        point_id:props?.pointId,
    })

    

    useEffect(()=>{
        if(props?.type === 'add'){
            setIsOpen(true);
        }else{
            setIsOpen(false)
        }
    },[props?.isOpen])


    const handleEdit = (isEdit) => {
        if (isEdit) {
            setEdit(false);
            setIsAdd(false);
            setDelete(false);
        } else {
            setEdit(true);
            setIsAdd(false);
            setDelete(false);
        }
    }
    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
            props?.handleModalClose()
        }
    })


    const handleChange = (e) => {
        setAddList({
            ...addList,
            [e?.target?.name]: e?.target?.value,
        })
  }

 
  const getFeaturesData = () => {
    axios.get(`/listing-features/all-features-by-category/${props?.cat_id}`).then(
      res => {
          setFeatures(res?.data);

      }
    ).catch(err => { console.log('Errori', err) });
  }

useEffect(()=>{
    getFeaturesData();
},[])

  const handleList = (e) => {
    e.preventDefault();
    axios.post('/point-features', addList)
      .then(data => {
        props?.handleModalClose();
        props?.getData();
        toast.success('Të dhënat e listes janë shtuar me sukses!');
      }
      ).catch(err => {
        toast.error('Te dhenat nuk jane shtuar!');
        console.log(err);
      });
  }



    if (props?.isOpen) {
        return (
            <>
                <ModalPortal>
                    <ModalWholePage onClick={() => props.handleModalClose()} />
                    <ModalStyle>
                        
                        <ModalButtonsPosition>
                            {props?.type !== "add" ? (
                                <>
                                    <ModalHandleButtons edit onClick={() => handleEdit(isEdit)} >
                                        {isEdit ? <FaTimes role="button" /> : <FaEdit role="button" />}
                                    </ModalHandleButtons>

                                    {!isEdit &&
                                        <ModalHandleButtons close onClick={() => props?.handleModalClose()} >
                                            <FaTimes role="button" />
                                        </ModalHandleButtons>}

                                </>
                            ) : (
                                <ModalHandleButtons
                                    close
                                    onClick={() => props?.handleModalClose()}
                                ><FaTimes role="button" />
                                </ModalHandleButtons>
                            )}
                        </ModalButtonsPosition>
                        <h4 className="text-center">{props?.type !== 'add' ? "Edit Feature" : "Add Feature"}</h4>
               
                        <ModalForm  
                        >
                           
                        
                            <div class="mb-3">
                              <select className="form-control"
                                onChange={handleChange}
                               name="feature_id"
                                placeholder="test"
                                     >
                                  <option value="" selected disabled>Select Feature</option>
                                  {features?.map((el) => {
                                        return <option value={Number(el?.id)} id={el?.id} selected={Number(props?.data?.feature_id) == el?.id ? "selected" : false}>{el?.name}</option>
                                    })}
                                </select>
                            </div>
                            {isEdit == false && props?.type != "add" ? "" :
                                <ModalButtonsPosition>
                                    
                                    <ModalMainButtons onClick={
                            props?.type === 'add' ? handleList : ""} disabled={submitting}>{props?.type === 'add' ? "Shto" : "Edit"}</ModalMainButtons>
                                </ModalButtonsPosition>
                            }
                        </ModalForm>
                    </ModalStyle>
                </ModalPortal>
            </>
        )
    }

}
export default PointFeaturesModal