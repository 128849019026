export const SET_SELECTED_LANG = "SET_SELECTED_LANG";
export const LOGMEIN = "LOGMEIN";
export const SET_USER_NAME = "SET_USER_NAME";
export const SET_SIDEBAR = "SET_SIDEBAR";
export const SET_USER_ID = 'SET_USER_ID';
export const SET_AUTH = 'SET_AUTH';
export const RM_AUTH = 'RM_AUTH';
export const SET_CITY_ID = 'SET_CITY_ID';

// export const SET_DATAFILTER = "SET_DATAFILTER";
