import { SET_SELECTED_LANG, SET_logged_in,LOGMEIN, SET_USER_NAME, SET_SIDEBAR ,SET_DATAFILTER,SET_USER_ID,SET_AUTH,RM_AUTH,SET_CITY_ID} from "./types";

const INITIAL_STATE = {
    selected_lang: 'AL',
    logged_in: false,
    username: "",
    sidebar: "",
    dataFilter : "",
    auth: null,
    user_id: null,
    city_id: null,
    user_role: null,
};
const initialReducer = (state = INITIAL_STATE, action) => {
    console.log("aksion",action)
    switch (action.type) {
        case SET_SELECTED_LANG:
            return { ...state, selected_lang: action.data }
        case SET_AUTH:
             return { ...state, auth: action.auth };    
        case SET_USER_ID:
            return { ...state, user_id: action.user_id, user_role: action.user_role };
        case SET_CITY_ID:
            return { ...state, city_id: action.data};
        // case SET_logged_in:
        //     return { ...state, logged_in: action.data }
        case LOGMEIN:
            if (state.logged_in === false) {
                return {
                    ...state, logged_in: true
                }
            } else return {
                ...state, logged_in: false
            };
        case SET_USER_NAME:
            return { ...state, username: action.data }
        case SET_SIDEBAR:
            return { ...state, sidebar: action.data }
        default: return state;
        case RM_AUTH:
            return {
                ...state, auth: ''
            };
        // case SET_DATAFILTER:
        //     return { ...state, dataFilter: action.data }
    }
};

export default initialReducer;