import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { setSideBar } from "../../redux/Functions/actions.js";
import { connect } from "react-redux";
import "./points.scss";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
  useMap
} from "react-leaflet";
import { PointStyle } from "./pointStyle";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaExclamationTriangle } from "react-icons/fa";

import { TableStyleEvents } from "../events/eventsStyle"
import { MainContent } from "./../styles/MainLayout"
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
// import closeIcn from "../../Images/close.svg";
import { ModalStyle, ModalHandleButtons, ModalClose, ModalForm, ModalMainButtons, ModalButtonsPosition, ModalWholePage, ModalButtons } from "../styles/ModalStyle"
import { AddButton, TableStyle, PaginationStyle } from "../styles/MainLayout.js";


import { FaTimes } from "react-icons/fa";
import ListingPointFeatures from "../points_features/listingPointFeatures.js";
import WorkingTime from "../workingTime/workingTime.js";
// Make sure to bind modal to your appElement (https://reactcommunity.org/../../portal/ModalPortal/accessibility/)
// Modal.setAppElement("#root");

const EditPoint1 = (props) => {
  // alert("Test")
  const [currentLocation, setCurrentLocation] = useState(null);
  const [userdata, adduserdata] = useState([]);
  const [languages, addlanguages] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [mapValidation, setMapValidation] = useState("");
  const [mapData, setMapData] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [mapState, setMapState] = useState([0, 0]);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const [modalIsOpen4, setIsOpen4] = React.useState(false);
  const [modalIsOpen5, setIsOpen5] = React.useState(false);
  const [modalIsOpenDelete, setModalIsOpenDelete] = React.useState(false);
  const [modalIsOpenAudio6, setIsOpenAudio6] = React.useState(false);
  const [modalIsOpenImage7, setIsOpenImage7] = React.useState(false);
  const [center, setCenter] = useState([0, 0]);
  const [located, setLocated] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [countries, setCountries] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [imgError, setImgError] = useState("");
  const [audio, setAudio] = useState(null);
  const [image, setImage] = useState(null);
  const [singleData, setSingleData] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [descriptionData, setDescriptionData] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [successDeletedMsg, setSuccessDeletedMsg] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [descriptonPutID, setDescriptionPutID] = useState("");
  const [audioPutID, setAudioPutID] = useState("");
  const [imagePutId, setImagePutID] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [previousCenter, setPreviousCenter] = useState(null);

  const [eventExists, setEventExists] = useState(true);

  const navigate = useNavigate();

  const [imagee, setImagee] = useState();
  let subtitle;
  let cId = "";
  const { idParams } = useParams();
  console.log("imagee", imagee)

  const idd = idParams;

  async function openConfirmModal(id) {
    console.log("idddddddddd", id);
    await setDeleteId(id);

    setModalIsOpenDelete(true);
  }

  function closeConfirmModal() {
    setModalIsOpenDelete(false);
  }

  function openModal2() {
    setIsOpen2(true);
  }

  function closeModal2() {
    setIsOpen2(false);
  }

  function openModal3() {
    setIsOpen3(true);
  }

  function closeModal3() {
    setIsOpen3(false);
  }

  function openModal4() {
    setIsOpen4(true);
  }

  function closeModal4() {
    setIsOpen4(false);
    setImagee();
  }

  async function openModal5(id) {
    setDescriptionPutID(id);
    await getSingleContent(id);
    await setIsOpen5(true);
  }

  function closeModal5() {
    setIsOpen5(false);
  }

  async function openModal6(id) {
    setAudioPutID(id);
    await getSingleAudio(id);
    await setIsOpenAudio6(true);
  }

  function closeModal6() {
    setIsOpenAudio6(false);
  }

  async function openModal7(id) {
    setImagePutID(id);
    // setImagePutId(id);
    await getSingleImage(id);
    await setIsOpenImage7(true);
  }

  function closeModal7() {
    setIsOpenImage7(false);
  }

  const uploadAudioFile = async (e) => {
    e.preventDefault();

    let file = e?.target.files[0];

    if (!file) {
      setImgError("Please select an audio.");
      setDisabled(true);
      return;
    }

    if (!file.name.match(/\.(mp3|wav|flac)$/)) {
      setImgError("Please select a valid image. (mp3, wav, flac)");
      setDisabled(true);
      return;
    }

    if (file && file.name.match(/\.(mp3|wav|flac)$/)) {
      await setAudio(file);
      setDisabled(false);
    }
  };

  const uploadFile = async (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setImagee(URL.createObjectURL(e.target.files[0]));
      setImagePutID()
      setSelectedImage(e.target.files[0]);
      // console.log("ddddd",e.target.files[0])
    }
    let file = e?.target.files[0];

    if (!file) {
      setImgError("Please select an image.");
      setDisabled(true);
      return false;
    }

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      setImgError("Please select a valid image. (jpg, jpeg, png)");
      setDisabled(true);
      return false;
    }

    if (file && file.name.match(/\.(jpg|jpeg|png)$/)) {
      await setImage(file);
      setDisabled(false);
    }
  };

  const uploadFileEdit = async (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setImagee(URL.createObjectURL(e.target.files[0]));
      // console.log("ddddd",e.target.files[0])
    }
    let file = e?.target.files[0];

    if (!file) {
      setImgError("Please select an image.");
      setDisabled(true);
      return false;
    }

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      setImgError("Please select a valid image. (jpg, jpeg, png)");
      setDisabled(true);
      return false;
    }

    if (file && file.name.match(/\.(jpg|jpeg|png)$/)) {
      await setImage(file);
      setDisabled(false);
    }
  };

  const uploadImage = async (event) => {

    event.preventDefault();
    let languageValue = event.target[1].value;

    const imageUpload = new FormData();
    imageUpload.append("image", image, image?.name);
    imageUpload.append("lang", languageValue);
    const uploadedImageUrl = languageValue; // Replace with the URL of the uploaded image
    setImagee(uploadedImageUrl);

    try {
      let response = await axios.post(`point-data/image/${idd}`, imageUpload);
      console.log(response);
      //   setSuccessCall("Success");
      toast.success("Image uploaded Successfully!");
      getImageData();
      setImagee();      // setTimeout(() => {
      closeModal4();
      // }, 1000);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  const uploadContent = async (event) => {
    event.preventDefault();
    let languageValue = event.target[1].value;
    let content = event.target.content.value;
    try {
      let response = await axios.post(`point-data/description/${idd}`, {
        lang: languageValue,
        content: content,
      });
      //   setSuccessCall("Success");
      toast.success("Content uploaded Successfully!");
      getDescriptionData();
      // setTimeout(() => {
      closeModal2();
      // }, 1000);
      console.log(response);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  const uploadAudio = async (event) => {
    event.preventDefault();
    let languageValue = event.target[1].value;
    const audioUpload = new FormData();
    audioUpload.append("audio", audio, audio?.name);
    audioUpload.append("lang", languageValue);
    try {
      let response = await axios.post(`point-data/audio/${idd}`, audioUpload);
      console.log(response);
      //   setSuccessCall("Success");
      toast.success("Audio uploaded Successfully!");
      getAudioData();
      // setTimeout(() => {
      closeModal3();
      // }, 1000);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  // let id = props?.match.params.id;

  let map;

  const latLongFunc = async (e) => {
    const ud = Object.assign({}, userdata);
    ud.latitude = e[0];
    ud.longitude = e[1];
    await adduserdata(ud);
  };

  
  const getCurrentLocation = (e) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation([latitude, longitude]);
        setMapState([0,0]);
        setCenter([latitude,longitude])
        const ud = Object.assign({}, userdata);
        ud.latitude = latitude;
        ud.longitude = longitude;
        adduserdata(ud);
      }, (error) => {
        console.error("Error getting location:", error);
      });
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  // console.log('map data yyyyyyyyyyy', mapData)

  const latitude = (e) => {
    const ud = Object.assign({}, mapData);
    ud.latitude = e.target.value;
    setMapData(ud);
  };
  const longitude = (e) => {
    const ud = Object.assign({}, mapData);
    ud.longitude = e.target.value;
    setMapData(ud);
  };

  // console.log('leaflet map')

  const getPoint = async () => {
    try {
      let response = await axios.get(`/point/id?id=${idd}`);
      adduserdata(response?.data);
      console.log("first",response.data.city_id , props?.city_id)
      if (response.data.city_id !== props?.city_id) {
        // window.location.href="/";
        navigate("/");
        return;
  }
      setCenter([
        Number(response?.data?.latitude),
        Number(response?.data?.longitude),
      ]);
    } catch (error) {
      setEventExists(false)
      // toast.error('Something went wrong!')
      console.log(error);
    }
  };

  

  useEffect(() => {
    getImageData();
    getDescriptionData();
    getAudioData();
    getCategories();
    getSubCategories();
    getPoint();
    getCities();
    getAllCountries();
    axios
      .get("languages/all")
      .then((data) => {
        addlanguages(data.data);
        setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, [idd, singleData]);

  // const defValues = () => {
  //     const ud = Object.assign({}, userdata);
  //     ud.auth = 'initial-auth';
  //     ud.mobileauth = 'initial-auth';
  //     adduserdata(ud);
  // }

  const pointName = (e) => {
    const ud = Object.assign({}, userdata);
    ud.name = e.target.value;
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const def_lang = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.def_lang = e.target.value;
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const country = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.country_id = e.target.value;
    console.log("ZZZZZZZZZZZZZZZZZZ", ud.country);
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const city = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.city_id = e.target.value;

    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const category = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.category_id = e.target.value;
    console.log(ud);
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const subCategory = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.subcategory_id = e.target.value;
    console.log(ud);
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const active = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.active = e.target.value;
    adduserdata(ud);
    // console.log('seeme',userdata)
  };
  const visiblilty = e => {
    const ud = Object.assign({}, userdata);
    ud.is_visible = e.target.value
    adduserdata(ud);
}

  const langID = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.lang_id = e.target.value;
    adduserdata(ud);
    console.log("seeme", userdata);
  };
  // const buttonState = () => {
  //     setDisableButton(!disableButton);
  // }

  // console.log('heheh', disableButton);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("point", {
        user_id: 1,
        ...userdata,
      })
      .then((res) => {
        toast.success("Point has been edited successfully!");
        console.log(res);
      })
      .catch((error) => {
        toast.error("Something went wrong!");
        console.log(error);
      });
  };
  // console.log('zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz' , Object.keys(userdata).length );
  // console.log(languages);
  //
  // let objectLength = Object.keys(userdata).length;

  const submitCoordinates = async (event) => {
    event.preventDefault();

    try {
      let response = await axios.put("point/location", {
        id: 4,
        ...mapData,
      });
      setMapValidation("Saved");
      console.log(response);
      // setTimeout(() => {
      setMapValidation("");
      // }, 3000);
    } catch (error) {
      setValidationError("Failed");
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      let response = await axios.get("point-categories/all");
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCities = async () => {
    try {
      let response = await axios.get("cities/all");
      setCities(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  function MyComponent() {
    // useEffect(()=>{
    //     map.locate();
    // },[])
    map = useMapEvents({
      // whenReady: () => {
      //     console.log('loaded')
      //     locationCenter();
      // },
      click: async (e) => {
        let i = await map.mouseEventToLatLng(e.originalEvent);
        await setMapState([Number(i.lat), Number(i.lng)]);
        console.log(map);
        await latLongFunc([Number(i.lat), Number(i.lng)]);
        // await latitude(i.lat);
        //  latitude(i.lat).then(()=>longitude(i.lng));
        // setTimeout(()=>latitude(i.lat), 105);
        console.log("shop data inside click", userdata);
        // map.locate();
      },
      locationfound: async (location) => {
        if (userdata?.longitude === 0 && userdata?.latitude === 0) {
          latLongFunc([location.latlng.lat, location.latlng.lng]);
          await map.panTo([
            Number(userdata?.latitude),
            Number(userdata?.longitude, {
              animate: false,
            }),
          ]);
          await setCenter([
            Number(userdata?.latitude),
            Number(userdata?.longitude),
          ]);
        }
        console.log("location found:", [
          location.latlng.lat,
          location.latlng.lng,
        ]);
        if (userdata != "") {
          console.log("po hin te shopi", [
            Number(userdata?.latitude),
            Number(location?.longitude),
          ]);
          console.log("centraaaa", [
            Number(userdata?.latitude),
            Number(userdata?.longitude),
          ]);
          await map.panTo([
            Number(userdata?.latitude),
            Number(userdata?.longitude, {
              animate: false,
            }),
          ]);
          await setCenter([
            Number(userdata?.latitude),
            Number(userdata?.longitude),
          ]);
        } else {
          console.log("po hin te location default");
          await map.panTo([
            Number(location.latlng.lat),
            Number(location.latlng.lng, {
              animate: false,
            }),
          ]);
          await setCenter([
            Number(location.latlng.lat),
            Number(location.latlng.lng),
          ]);
        }
        // );
        // setCenter([Number(location.latlng.lat), Number(location.latlng.lng)])
        setMapState([Number(location.latlng.lat), Number(location.latlng.lng)]);
        setLocated(true);
        map.stopLocate();
        // console.log(mapState)
      },
    });
    if (located == false) {
      map.locate();
    }
    return null;
  }

  function FlyMapTo() {
    const map = useMap();

    useEffect(() => {
      if (previousCenter !== center) {
        map.flyTo(center);
        setPreviousCenter(center);
      }
    }, [center, map, previousCenter]);

    return null
  }

  const getAllCountries = async () => {
    try {
      let response = await axios.get("countries/all");
      setCountries(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSubCategories = async () => {
    try {
      let response = await axios.get("point-subcategories/all");
      setSubCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAudioData = async () => {
    try {
      let response = await axios.get(`point-data/audio/${idd}`);
      console.log("", response?.data);
      setAudioData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDescriptionData = async () => {
    try {
      let response = await axios.get(`point-data/description/${idd}`);
      setDescriptionData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getImageData = async () => {
    try {
      let response = await axios.get(`point-data/image/${idd}`);
      setImageData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSingleContent = async (id) => {
    try {
      let response = await axios.get(`point-data/single/${id}`);
      setSingleData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [singleAudio, setSingleAudio] = useState([]);

  const getSingleAudio = async (id) => {
    try {
      let response = await axios.get(`point-data/single/${id}`);
      setSingleData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSingleImage = async (id) => {
    try {
      let response = await axios.get(`point-data/single/${id}`);
      console.log("response...................", response.data);
      setSingleData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const editContent = async (event) => {
    event.preventDefault();
    let languageValue = event.target[1].value;
    let content = event.target.content.value;

    try {
      let response = await axios.put(`point-data/description/${descriptonPutID}`, {
        lang: languageValue,
        content: content,
      });
      //   setSuccessCall("Success");
      getDescriptionData();
      getSingleContent(descriptonPutID);
      toast.success("Content has been edited successfully!");
      // setTimeout(() => {
      closeModal5();
      // }, 1000);
      console.log(response);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  const editAudio = async (event) => {
    event.preventDefault();

    let languageValue = event.target[1].value;
    const audioUpload = new FormData();

    audioUpload.append("audio", audio, audio?.name);
    audioUpload.append("lang", languageValue);

    console.log("content content content", audioUpload);

    try {
      let response = await axios.put(`point-data/audio/${audioPutID}`, audioUpload);
      getSingleAudio(cId);
      getAudioData();
      //   setSuccessCall("Success");
      toast.success("Audio has been edited successfully!");
      console.log(response);
      // setTimeout(() => {
      closeModal6();
      // }, 1000);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  const editImage = async (event) => {
    event.preventDefault();

    let languageValue = event.target[1].value;
    const imageUpload = new FormData();

    imageUpload.append("image", image, image?.name);
    imageUpload.append("lang", languageValue);
    try {
      let response = await axios.put(`point-data/image/${imagePutId}`, imageUpload);

      getSingleContent(cId);
      getImageData();
      //   setSuccessCall("Success");
      toast.success("Image has been edited successfully!");
      // setTimeout(() => {
      setImagePutID(null);
      closeModal7();
      // }, 1000);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };
  console.log("setImagePutId", imagePutId)

  const deleteContent = async (event, id) => {
    event.preventDefault();

    try {
      let response = await axios.delete(`point-data/?id=${id}`);

      getDescriptionData();
      getSingleContent(cId);
      // setTimeout(() => {
      closeModal5();
      // }, 1000);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAudio = async (event, id) => {
    event.preventDefault();

    try {
      let response = await axios.delete(`point-data/?id=${id}`);
      getSingleAudio(cId);
      getAudioData();
      console.log(response);
      toast.success("Audio has been deleted successfully!");
      // setTimeout(() => {
      closeModal6();
      // }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteImage = async (event, id) => {
    event.preventDefault();

    try {
      let response = await axios.delete(`point-data/?id=${id}`);
      console.log(response);
      getSingleContent(cId);
      getImageData();
      toast.success("Content has been deleted successfully!");
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  console.log("HAHA",props?.city_id)
  //
  //
  // console.log('heh', subCategories)
  // console.log('hjeeh', categories)
  //
  // console.log('userzzzrz,', userdata)
  //
  //
  // console.log(',,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,', singleData)

  let id = "";
  const confirmButton = (event, id) => {
    console.log("delete id ", deleteId);
    console.log("log incoming", id);
    id = id;
    if (id == 1) {
      deleteImage(event, deleteId);
      getImageData();
      getAudioData();
      getDescriptionData();
      setSuccessDeletedMsg("Has been deleted successfully");
      // setTimeout(() => {
      closeConfirmModal();
      // }, 2000);
    } else {
      console.log("nuk u fsih kurgjo");
      // setTimeout(() => {
      closeConfirmModal();
      // }, 1000);
    }
  };

  if (!eventExists) {
    return <Navigate to="/" />;
  }

  return (
    <PointStyle className={props?.sidebarCollapse !== "collapsed" ? "notCollapsedSideBar text-left" : "collapsedSideBar  mt-0 text-left"}>
      <MainContent collapsedEvent={props?.sidebarCollapse} >


        <div className="row justify-content-center">
          <h1 className="ps-4 pt-1">Edit Point</h1>
          {/*<button onClick={buttonState} type="btn btn-success button">Add Points</button>*/}
          <div className="row">
            <div className="col-md-6 col-12">
              <form className='formAdd w-100'>
                <div className={"myMap"}>
                  <div>
                    <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>
                    {/*<button onClick={openModal} className={'map-button showme'}><i*/}
                    {/*    className="fas fa-map-marker-alt"></i><span className={'hideme map-button-txt'}>Add Point Location</span>*/}
                    {/*</button>*/}
                    <div className="row mb-5">
                      <MapContainer center={center} zoom={13} scrollWheelZoom={false} className="mapHeight">
                        <MyComponent />
                        <FlyMapTo />
                        <TileLayer
                          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {userdata != "" ? (
                          <Marker position={[userdata.latitude, userdata.longitude]}>
                            <Popup>
                              A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup>
                          </Marker>
                        ) : mapState != "" ? (
                          <Marker position={[mapState[0], mapState[1]]}>
                            <Popup>
                              A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup>
                          </Marker>
                        ) : (
                          ""
                        )}
                      </MapContainer>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {!isloading ? (
              ""
            ) : (
              <div className="col-md-6 col-12">
                <div className="text-center">

                    <span className="text-center"><strong>Lat: </strong> {userdata.latitude},<strong> Long:</strong>  {userdata.longitude}</span>
                </div>
                  <button onClick={getCurrentLocation} className="text-light btn btn-success w-100 p-2 mt-2">
                    <i className="fas fa-location-arrow"></i>
                    <span className='ms-2'>Get Current Location</span>
                </button>
                <form onSubmit={handleSubmit} className='formAdd w-100'>
                  <div className={"row p-1"}>
                  </div>
                  <span className="p-2 text-muted">Point Name</span>
                  <input
                    type="text"
                    className="form-control"
                    required
                    title="This field is required"
                    placeholder="Point name"
                    defaultValue={userdata?.name}
                    onChange={pointName}
                  ></input>
                   <span className="p-2 text-muted">Select Category</span>
                  <select className="form-control" onChange={category} value={userdata?.category_id}>
                    <option disabled>
                      Select Category
                    </option>
                    {categories?.map((el) => {
                      return <option value={el.id}>{el.name}</option>;
                    })}
                  </select>
                  <span className="p-2 text-muted">Select Sub-Category</span>
                  <select className="form-control"
                    onChange={subCategory}
                    value={userdata?.subcategory_id}
                  >
                    <option disabled>
                      Select Sub-Category
                    </option>
                    {subCategories?.map((el) => {
                      return <option value={Number(el.id)}>{el.name}</option>;
                    })}
                  </select>
                  {/* <span className="p-2 text-muted">Select City</span>
                  <select className="form-control" onChange={city} defaultValue={userdata?.city_id}>
                    <option disabled defaultValue={userdata?.city_id} selected>
                      Select City
                    </option>
                    {cities?.map((el) => {
                      return <option value={Number(el.id)}>{el.name}</option>;
                    })}
                  </select> */}
                  <span className="p-2 text-muted">Select Country</span>
                  <select className="form-control" onChange={country} value={userdata?.country_id}>
                    <option disabled>
                      Select Country
                    </option>
                    {countries?.map((el) => {
                      return <option value={Number(el.id)} >{el.name}</option>;
                    })}
                  </select>
                  <span className="p-2 text-muted">Select Status</span>
                  <select className="form-control" defaultValue={userdata?.active ? 1 : 0} onChange={active}>
                    <option>Select Status</option>
                    <option value={0}>Passive</option>
                    <option value={1}>Active</option>
                  </select>
                  <span className="p-2 text-muted">Select Visibility</span>
                  <select className="form-control" defaultValue={userdata?.is_visible ? 1 : 0} onChange={visiblilty}>
                    <option>Select Status</option>
                    <option value={0}>Passive</option>
                    <option value={1}>Active</option>
                  </select>

                  <ModalButtonsPosition className='justify-content-center mt-2'>
                    <ModalMainButtons type="submit" className='w-100' >Edit Point</ModalMainButtons>
                  </ModalButtonsPosition>
                </form>
              </div>
            )}

          </div>
        </div>


        <div>
          <div className="row buttons-group">
            <div className="col-md-12 mb-3 mt-4">

              <h2>Content</h2>
              <div className="row">
                <div className="col-md-12">
                  {/* <div className="col-md-12">
                {descriptionData?.map((el, i) => {
                  return (
                    <div className="listimi showme">
                      <div className="row">
                        <div className="col-7">{el?.content}</div>
                        <div className="col-2">{el?.lang}</div>
                   
                        <div className="col-1 hideme">
                          <Link
                            className={"fa fa-edit"}
                            onClick={() => openModal5(el?.point_data_id)}
                          ></Link>
                        </div>
                        <div className="col-1 hideme">
                          <Link
                            className={"fa fa-trash"}
                            onClick={() => openConfirmModal(el?.point_data_id)}
                          ></Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div> */}
                  <TableStyleEvents tableEditPoint className="table-responsive"  >
                    <Table className="custom-table table table-hover" >
                      <Thead>
                        
                        <Tr>
                          <Th className="table-header col-md-3">Content</Th>
                          <Th className="table-header col-md-3">Language</Th>
                          <Th className="table-header col-md-2">Edit</Th>
                          <Th className="table-header col-md-2">Delete</Th>
                          <Th className="table-header col-md-2">
                            <ModalButtonsPosition className=''>
                              <ModalMainButtons onClick={openModal2}><i className="fas fa-plus" /> Add Content</ModalMainButtons>
                            </ModalButtonsPosition>
                          </Th>

                        </Tr>
                      </Thead>
                      <Tbody>
                        
                        {descriptionData?.map((el, i) => {
                          return (
                            <>
                              <Tr>
                              <Td>{el?.content?.length > 30 ? el?.content?.slice(0, 30) + '...' : el?.content}</Td>
                                <Td>{el?.lang}</Td>
                                <Td> <Link
                                  onClick={() => openModal5(el?.point_data_id)}
                                ><FaEdit className="text-dark" /></Link></Td>
                                <Td> <Link
                                  className={"fa fa-trash text-dark"}
                                  onClick={() => openConfirmModal(el?.point_data_id)}
                                ></Link></Td>
                                <Td></Td>
                              </Tr>
                            </>
                          )
                        })}
                      </Tbody>
                    </Table>
                  </TableStyleEvents>
                </div>
              </div>
            </div>
            <div className="col-md-12 mb-3 mt-4">
              <h2>Audio</h2>
              <div className="row">
                <div className="col-md-12">
                  <TableStyleEvents tableEditPoint className="table-responsive"  >
                    <Table className="custom-table table table-hover" >
                      <Thead>
                       
                        <Tr>
                          <Th className="table-header col-md-3">Content</Th>
                          <Th className="table-header col-md-3">Language</Th>
                          <Th className="table-header col-md-2">Edit</Th>
                          <Th className="table-header col-md-2">Delete</Th>
                          <Th className="table-header col-md-2"><ModalButtonsPosition className=''>
                            <ModalMainButtons onClick={openModal3}><i className="fas fa-plus" /> Add Audio</ModalMainButtons>
                          </ModalButtonsPosition></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {audioData?.map((el, i) => {
                          return (
                            <>
                              <Tr>
                                <Td>{el?.data_type_name}</Td>
                                <Td>{el?.data_type}</Td>
                                <Td> <Link
                                  // className={"fa fa-edit"}
                                  onClick={() => openModal6(el?.point_data_id)}
                                ><FaEdit className="text-dark" /></Link></Td>
                                <Td> <Link
                                  className={"fa fa-trash text-dark"}
                                  onClick={() => openConfirmModal(el?.point_data_id)}
                                ></Link></Td>
                                <Td></Td>
                              </Tr>
                            </>
                          )
                        })}
                      </Tbody>
                    </Table>
                  </TableStyleEvents>
                </div>
              </div>
            </div>
            <div className="col-md-12 mb-3 mt-4">
              <h2>Images</h2>
              <div class=" mt-2">
                <div class="cards-1 section-gray ">
                  {/* <button className="btn-plus" onClick={openModal4}>
              <i className="fas fa-plus"></i>
            </button> */}
                  <ModalButtonsPosition className='me-3'>
                    <ModalMainButtons onClick={() => openModal4()}><i className="fas fa-plus " /> Add Image</ModalMainButtons>
                  </ModalButtonsPosition>
                  <div class="row  row-cols-1 row-cols-md-3 row-cols-lg-5 g-3">
                    {imageData?.map((el, i) => {
                      return (
                        <>
                          <div class="col p-3">
                            <div class="card ">
                              <div class="card-image">
                                <a href="#"> <img class="img" src={process.env.REACT_APP_UPLOADS + el?.url} /> </a>
                              </div>
                              <div class="d-flex justify-content-space imageButtons">
                                  <div className="col-6 text-center">
                                    <div type="submit" onClick={() => openModal7(el?.point_data_id)}><FaEdit className="text-dark" /></div>
                                  </div>
                                  <div className="col-6 text-center mb-3">
                                    <div  type="submit" onClick={() => openConfirmModal(el?.point_data_id)}><i className={"fa fa-trash text-dark"}/></div>
                                  </div>
                                </div>
                            </div>
                            {/* <div class="row row-cols-1 row-cols-md-3 g-4">
    <div class="card h-100 ">
      <img  class="img" src={process.env.REACT_APP_UPLOADS + el?.url} 
        alt="Skyscrapers" />
         <div class="ftr">
                                <div class="d-flex justify-content-space imageButtons">
                                  <div className="col-6 text-center">
                                    <div type="submit" onClick={() => openModal7(el?.point_data_id)}><FaEdit className="text-dark" /></div>
                                  </div>
                                  <div className="col-6 text-center mb-3">
                                    <div  type="submit" onClick={() => openConfirmModal(el?.point_data_id)}><i className={"fa fa-trash text-dark"}/></div>
                                  </div>
                                </div>
                              </div>

    </div>

</div> */}
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <ListingPointFeatures pointId={idd} category_id={userdata?.category_id} />
      <WorkingTime pointId={idd}/>
      </MainContent>
      <div className="d">
        {modalIsOpen5 ? (
          <div className="row modal-table">
            <div className="col-md-12">
              <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>


              {/* EDIT CONTENT MODAL */}
              <Modal
                isOpen={modalIsOpen5}
                // onAfterOpen={afterOpenModal2}
                onRequestClose={closeModal5}
                className="mymodal"
                contentLabel="Example Modal"
              >




                <div className="row audio-part">
                  <ModalButtonsPosition>
                    <ModalHandleButtons className="mt-3"
                      onClick={() => closeModal5()}
                    ><FaTimes role="button" />
                    </ModalHandleButtons>
                  </ModalButtonsPosition>
                  <form onSubmit={editContent} className='formAdd' >
                    <div className=" mb-3">
                      <h3>Edit Content</h3>
                    </div>
                    {/* <button onClick={() => closeModal5()}>X</button> */}

                    <div className="col-md-12 ">
                      <div className="mb-3 form-modal ">
                        <span className="text-dark mb-1">Content</span>
                        <textarea
                          className="form-control"
                          name="content"
                          defaultValue={singleData?.content}
                          id="exampleFormControlTextarea1"
                          title="This field should not be left blank."
                          required
                          rows="3"
                        ></textarea>
                      </div>
                      <p>{imgError}</p>
                      <div className=" mb-3">
                        <span className="text-dark mb-1">Options</span>
                        <select
                          className="form-control"
                          defaultValue={singleData?.lang_id}
                          id="inputGroupSelect01"
                          title="This field should not be left blank."
                        >

                          <option
                            name="lang_id"
                            disabled
                            // defaultValue={singleData?.lang_id}
                            selected
                          >
                            Select Language
                          </option>
                          {languages?.map((el) => {
                            return (
                              <option value={Number(el.id)}>{el.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      {/* <div className="button-left">
                        <button
                          className="btn btn-outline-secondary submit-button"
                          type="submit"
                          disabled={isDisabled}
                          id="inputGroupFileAddon03"
                        >
                          Edit Content
                        </button>
                      </div> */}
                      <ModalButtonsPosition>
                        <ModalMainButtons type="submit" disabled={isDisabled}>Edit Content</ModalMainButtons>
                      </ModalButtonsPosition>
                      {/* <p className="validation-msg-modal">successCall</p> */}
                    </div>
                  </form>
                </div>
              </Modal>
            </div>
          </div>
        ) : (
          ""
        )}

        {modalIsOpenAudio6 ? (
          <div className="row modal-table">
            <div className="col-md-12">
              <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

              {/* EDIT AUDIO MODAL */}
              <Modal
                isOpen={modalIsOpenAudio6}
                // onAfterOpen={afterOpenModal2}
                onRequestClose={closeModal6}
                className="mymodal"
                contentLabel="Example Modal"
              >
                <div className="row audio-part">
                  <ModalButtonsPosition>
                    <ModalHandleButtons className="mt-3"
                      onClick={() => closeModal6()}
                    ><FaTimes role="button" />
                    </ModalHandleButtons>
                  </ModalButtonsPosition>
                  <form onSubmit={editAudio} className='formAdd'>
                    <div className="">
                      <h3>Edit Audio</h3>
                    </div>
                    <div className="col-md-12">
                      <div className="audio-player">
                        <audio controls>
                          <source
                            src={process.env.REACT_APP_UPLOADS + singleData?.url}
                            type="audio/ogg"
                          />
                        </audio>
                      </div>
                      <div className="input-group mb-3">
                        <input
                          type="file"
                          className="form-control"
                          id="inputGroupFile03"
                          name="audio"
                          onChange={uploadAudioFile}
                          aria-describedby="inputGroupFileAddon03"
                          aria-label="Upload"
                          // required
                          title="This field should not be left blank."
                        />
                      </div>
                      <p>{imgError}</p>
                      <div>
                        <span className="text-dark mb-1">Options</span>
                        <select
                          className="form-control"
                          defaultValue={singleData?.lang_id}
                          id="inputGroupSelect01"
                          title="This field should not be left blank."
                        >
                          <option name="lang" disabled selected>
                            Select Language
                          </option>
                          {languages?.map((el) => {
                            return (
                              <option value={Number(el?.id)}>{el?.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <p></p>
                      {/* <div className="button-left">
                        <button
                          className="btn btn-outline-secondary submit-button"
                          type="submit"
                          disabled={isDisabled}
                          id="inputGroupFileAddon03"
                        >
                          Upload Audio
                        </button>
                      </div> */}
                      <ModalButtonsPosition>
                        <ModalMainButtons type="submit" >Edit Audio</ModalMainButtons>
                      </ModalButtonsPosition>
                      {/* <p className="validation-msg-modal">{successCall}</p> */}
                    </div>
                  </form>
                </div>
              </Modal>
            </div>
          </div>
        ) : (
          ""
        )}


        {modalIsOpenImage7 ? (
          <div className="row audio-part">
            <div className="col-md-12">
              <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

              {/* EDIT IMAGE MODAL */}
              <Modal
                isOpen={modalIsOpenImage7}
                // onAfterOpen={afterOpenModal2}
                onRequestClose={closeModal7}
                className="mymodal"
                contentLabel="Example Modal"
              >
                <div className="row audio-part">
                  <ModalButtonsPosition>
                    <ModalHandleButtons className="mt-3"
                      onClick={() => closeModal7()}
                    ><FaTimes role="button" />
                    </ModalHandleButtons>
                  </ModalButtonsPosition>
                  {/* <form onSubmit={editImage} className='formAdd'>

                    <div className="mb-3">
                      <h4>Edit Image</h4>
                    </div>
                    {/* <button onClick={() => closeModal7()}>X</button> */}


                  {/* <div className="col-md-12">
                      <div className="input-group mb-3">
                        <input
                          type="file"
                          className="form-control"
                          id="inputGroupFile03"
                          name="image"
                          onChange={uploadFileEdit}
                          aria-describedby="inputGroupFileAddon03"
                          aria-label="Upload"
                          title="This field should not be left blank."
                          required
                        />
                      </div>
                      <p>{imgError}</p> */}

                  {/* <div className="button-left">
                        <button
                          className="btn btn-outline-secondary submit-button"
                          type="submit"
                          disabled={false}
                          id="inputGroupFileAddon03"
                        >
                          Upload Image
                        </button>
                      </div> */}
                  {/* <ModalButtonsPosition>
                        <ModalMainButtons type="submit" >Upload Image</ModalMainButtons>
                      </ModalButtonsPosition> */}

                  {/* <p className="validation-msg-modal">{successCall}</p> */}
                  {/* </div>
                  </form>  */}


                  <form onSubmit={editImage} className="formAdd">
                    <div className="">
                      <h4 className="mb-5 text-center">Edit Image</h4>
                    </div>
                    <div className="col-md-12">
                      <div className="col-md-12">
                        <div className="text-center mb-4">
                          {imagee ? (
                            <img src={imagee} width="300" height="300" alt="Uploaded Image" />
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 mx-auto">
                      <div className="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                        <input
                          id="upload"
                          type="file"
                          onChange={uploadFileEdit}
                          className="form-control border-0"
                        />
                        <label
                          id="upload-label"
                          htmlFor="upload"
                          className="font-weight-light text-muted"
                        >
                          Choose file to edit
                        </label>
                        <div className="input-group-append">
                          <label
                            htmlFor="upload"
                            className="btn btn-light m-0 rounded-pill p-3"
                          >
                            <i className="fa fa-cloud-upload mr-2 text-muted"></i>
                            <small className="text-uppercase font-weight-bold text-muted">
                              Choose file
                            </small>
                          </label>
                        </div>
                      </div>
                      <p>{imgError}</p>


                      <ModalButtonsPosition className="justify-content-center">
                        <ModalMainButtons type="submit" >Edit</ModalMainButtons>
                      </ModalButtonsPosition>

                    </div>
                  </form>
                </div>
              </Modal>
            </div>
          </div>
        ) : (
          ""
        )}


        {modalIsOpenDelete ? (
          <div className="row ">
            <div className="col-md-12">
              <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

              {/* DELETE MODAL */}
              <Modal
                isOpen={modalIsOpenDelete}
                // onAfterOpen={afterOpenModal2}
                onRequestClose={closeConfirmModal}
                className="mymodal"
                contentLabel="Example Modal"
              >


                <ModalStyle deletePoint>
                  <div className="text-center">
                    <FaExclamationTriangle className='display-3 text-danger mb-5' />
                    <h3>Delete Point</h3>
                    <h4 className='text-secondary'>You're going to delete the "Point" Data.<br /> Are you sure?</h4>
                  </div>
                  <ModalButtonsPosition className='justify-content-between mt-5'>
                    <ModalMainButtons closeDeleteButton onClick={(event) => confirmButton(event, 0)}>No, Keep it.</ModalMainButtons>
                    <ModalMainButtons type="submit" deleteModal onClick={(event) => confirmButton(event, 1)}>Yes, Delete! </ModalMainButtons>
                  </ModalButtonsPosition>
                </ModalStyle>
              </Modal>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="row modal-table">
        <div className="col-md-12">
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

          {/* ADD CONTENT MODAL */}
          <Modal
            isOpen={modalIsOpen2}
            // onAfterOpen={afterOpenModal2}
            onRequestClose={closeModal2}
            className="mymodal"
            contentLabel="Example Modal"
          >
            <div className="row audio-part">
              <ModalButtonsPosition>
                <ModalHandleButtons className="mt-3"
                  onClick={() => closeModal2()}
                ><FaTimes role="button" />
                </ModalHandleButtons>
              </ModalButtonsPosition>
              <form onSubmit={uploadContent} className="formAdd">
                <div className="mb-3">
                  <h3  >Content</h3>
                </div>
                <div className="col-md-12">
                  {/* <h1 className="title-part">Content</h1> */}
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                    </label>
                    <textarea
                      className="form-control"
                      name="content"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Add Content"
                    ></textarea>
                  </div>
                  <p>{imgError}</p>
                  <div className="mb-3">
                    <span className="text-dark pb-2 ">Options</span>
                    <select
                      className="custom-select form-control"
                      id="inputGroupSelect01"
                      title="This field should not be left blank."
                      required
                    >
                      <option name="lang" disabled selected>
                        Select Language
                      </option>
                      {languages?.map((el) => {
                        return <option value={Number(el.id)}>{el.name}</option>;
                      })}
                    </select>
                  </div>

                  <ModalButtonsPosition>
                    <ModalMainButtons type="submit" className="justify-content-center">Add Content</ModalMainButtons>
                  </ModalButtonsPosition>
                </div>
              </form>
            </div>
          </Modal>
        </div>
      </div>
      <div>
        <div className="row modal-table">
          <div className="col-md-12">
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

            {/* UPLOAD AUDIO MODAL */}
            <Modal
              isOpen={modalIsOpen3}
              // onAfterOpen={afterOpenModal2}
              onRequestClose={closeModal3}
              className="mymodal"
              contentLabel="Example Modal"
            >
              <div className="row audio-part">
                <ModalButtonsPosition>
                  <ModalHandleButtons className="mt-3"
                    onClick={() => closeModal3()}
                  ><FaTimes role="button" />
                  </ModalHandleButtons>
                </ModalButtonsPosition>
                <form onSubmit={uploadAudio} className="formAdd">
                  <div className=" mb-3">
                    <h3>Upload Audio</h3>
                  </div>
                  <div className="col-md-12">
                    {/* <h1 className="title-part">Upload Audio</h1> */}
                    <div className="input-group mb-3">
                      <input
                        type="file"
                        className="form-control"
                        id="inputGroupFile03"
                        name="audio"
                        onChange={uploadAudioFile}
                        aria-describedby="inputGroupFileAddon03"
                        aria-label="Upload"
                        title="This field should not be left blank."
                        required
                      />
                    </div>
                    <p>{imgError}</p>
                    <div className=" mb-3">
                      <span className="text-dark pb-2">Options</span>
                      <select
                        className="custom-select form-control"
                        id="inputGroupSelect01"
                        title="This field should not be left blank."
                        required
                      >
                        <option name="lang" disabled selected>
                          Select Language
                        </option>
                        {languages?.map((el) => {
                          return (
                            <option value={Number(el.id)}>{el.name}</option>
                          );
                        })}
                      </select>
                    </div>
                    {/* <div className="button-left">
                      <button
                        className="btn btn-outline-secondary submit-button"
                        disabled={isDisabled}
                        type="submit"
                        id="inputGroupFileAddon03"
                      >
                        Upload
                      </button>
                    </div> */}
                    <ModalButtonsPosition>
                      <ModalMainButtons type="submit">Upload Audio</ModalMainButtons>
                    </ModalButtonsPosition>
                    {/* <p className="validation-msg-modal">{successCall}</p> */}
                  </div>
                </form>
              </div>
            </Modal>
          </div>
        </div>

        <div className="row audio-part">
          <div className="col-md-12">
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

            {/*UPLOAD IMAGE MODAL  */}
            <Modal
              isOpen={modalIsOpen4}
              // onAfterOpen={afterOpenModal2}
              onRequestClose={closeModal4}
              className="mymodal"
              contentLabel="Example Modal"
            >
              <div className="row audio-part">
                <ModalButtonsPosition>
                  <ModalHandleButtons className="mt-3"
                    onClick={() => closeModal4()}
                  ><FaTimes role="button" />
                  </ModalHandleButtons>
                </ModalButtonsPosition>
                <form onSubmit={uploadImage} className="formAdd">
                  <div className="">
                    <h4 className="mb-5 text-center">Upload Image</h4>
                  </div>
                  <div className="col-md-12">
                    <div className="col-md-12">
                      <div className="text-center mb-4">
                        {imagee ? (
                          <img src={imagee} width="300" height="300" alt="Uploaded Image" />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mx-auto">
                    <div className="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                      <input
                        id="upload"
                        type="file"
                        onChange={uploadFile}
                        className="form-control border-0"
                      />
                      <label
                        id="upload-label"
                        htmlFor="upload"
                        className="font-weight-light text-muted"
                      >
                        Choose file
                      </label>
                      <div className="input-group-append">
                        <label
                          htmlFor="upload"
                          className="btn btn-light m-0 rounded-pill p-3"
                        >
                          <i className="fa fa-cloud-upload mr-2 text-muted"></i>
                          <small className="text-uppercase font-weight-bold text-muted">
                            Choose file
                          </small>
                        </label>
                      </div>
                    </div>
                    <p>{imgError}</p>


                    <ModalButtonsPosition className="justify-content-center">
                      <ModalMainButtons type="submit" >Add Image</ModalMainButtons>
                    </ModalButtonsPosition>

                  </div>
                </form>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </PointStyle>
  );
};
const mapStateToProps = (state) => {
  console.log("state",state)
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebarCollapse: state?.data?.sidebar,
    user_id: state?.data?.user_id,
    city_id: state?.data?.city_id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditPoint1);
