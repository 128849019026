import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import "./register.scss"
import { useState } from 'react'
import jwt_decode from "jwt-decode";
import axios from '../../axios';
import {
  setAuth,
  setUserID,
  logMeIn,
  setCityID
} from "../../redux/Functions/actions";
import { toast } from "react-toastify";
import { Link, useNavigate } from 'react-router-dom'
const Register = (props) => {
  console.log("Login Props", props)
  const [userData, setUserData] = useState({});
  const [errors, addErrors] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [show, setShow] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [registerData, setRegisterData] = useState({});
  const [viewportSize, setViewportSize] = useState(window.innerWidth);
  const navigate = useNavigate();
  window.addEventListener("resize", () => {
    setViewportSize(window.innerWidth);
  });


  const email = e => {
    const ud = Object.assign({}, userData);
    ud.email = e.target.value;
    setUserData(ud);
  }

  const password = e => {
    const ud = Object.assign({}, userData);
    ud.pass = e.target.value;
    setUserData(ud);
  }
  const full_name = e => {
    const ud = { ...registerData }
    ud.full_name = e.target.value
    setRegisterData(ud);
    // console.log('seeme',userdata)
  }

  const emailForRegister = e => {
    // console.log(e)
    const ud = { ...registerData }
    ud.email = e.target.value
    setRegisterData(ud);
    // console.log('seeme',userdata)
  }
  const passForRegister = e => {
    // console.log(e)
    const ud = { ...registerData }
    ud.pass = e.target.value
    setRegisterData(ud);
    // console.log('seeme',userdata)
  }
  const phoneForRegister = e => {
    // console.log(e)
    const ud = { ...registerData }
    ud.phone = e.target.value
    setRegisterData(ud);
    // console.log('seeme',userdata)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userData.email && userData.pass) {
      axios.post('/login', userData)
        .then((res) => {
          if (res.data.token && res.data.token !== null) {
            // addErrors(false);
            window.localStorage.setItem('auth', res?.data?.token);
            let auth = window.localStorage.getItem('auth')
            if (auth !== null) {
              // window.localStorage.setItem('cd', res.data.token);
              // const userdata = decode(res.data.token);
              var decoded = jwt_decode(auth);
              props.setAuth(auth);
              props.setUserID(decoded?.id, decoded?.role);
              props.setCityID(decoded?.city_id);
              props?.logMeIn("true");
              // window.location = "/";
              window.location = "/Point";
              // navigate('/Point');
              // window.location.reload();
            }
            // window.location = "/users";
          } else {
            addErrors(res.data.error);
          }
        })
        .catch(err => {
          console.log('error incoming', err);
          toast.error(err?.response?.data?.error);

        })
    } else {
      addErrors(true);
    }
  }
  const handleRegister = (e) => {
    e.preventDefault();
    axios.post('/register', registerData)
      .then(data => {
        if (data.data.error === "This user has no Horeca Priviledges!") {
          toast.error(data.data.error);
          // setEmailFocus(true);
        } else {
          toast.success('User has been added successfully!')
          setIsSignUp(!isSignUp);
        }
        // window.history.back();

      })
      .catch(err => {
        toast.error('Something went wrong!')
        console.log(err)
      });

  }

  const handleButtonClick = () => {
    setIsSignUp(!isSignUp);
  };

  return (
    <div className="signUpForm">
            <div className={` row cont ${isSignUp ? 's--signup' : ''}`}>
            {/* <Link to="/"><i className="fa fa-home mt-3 overflow-hidden" aria-hidden="true"/></Link> */}
              <form className="sign-in " onSubmit={handleSubmit}>
                <h2>Welcome back,</h2>
                <label>
                  <span>Email</span>
                  <input type="email" onChange={email} required />
                </label>
                <label>
                  <span>Password</span>
                  <input type="password" onChange={password} required />
                </label>
                <button type="submit" className="submit">Sign In</button>
                {/* <label className={viewportSize > 600
            ? "d-none":""}>Dont have an account yet?<br/> <span onClick={handleButtonClick} className='fw-bolder'   style={{
              color:"#6ECDE9"
                  }}>Sign up here</span></label> */}
              </form>

              <div className="sub-cont">
              <Link to="/"><i className="fa fa-home mt-2 ms-2 overflow-hidden" aria-hidden="true"/></Link>
                <div className="imgSignUp" >
                  <div className="imgSignUp__text m--up">
                    <h2>New here?</h2>
                    <p>Sign up and discover a great number of new opportunities!</p>
                  </div>
                  <div className="imgSignUp__text m--in">
                    <h2>One of us?</h2>
                    <p>If you already have an account, just sign in.</p>
                  </div>
                  {/* <div className="imgSignUp__btn" onClick={handleButtonClick}>
                    <span className={`m--up ${isSignUp ? 'active' : ''}`}>Sign Up</span>
                    <span className={`m--in ${isSignUp ? '' : 'active'}`}>Sign In</span>
                  </div> */}
                </div>
                {/* <form className="sign-up" onSubmit={handleRegister}>
                  <h2>Time to feel like home,</h2>
                  <label>
                    <span>Name</span>
                    <input type="text" onChange={full_name} required />
                  </label>
                  <label>
                    <span>Email</span>
                    <input type="email" onChange={emailForRegister} required />
                  </label>
                  <label>
                    <span>Password</span>
                    <input type="password" autocomplete="new-password" onChange={passForRegister} required />
                  </label>
                  <label>
                    <span>Phone number</span>
                    <input type="number" onChange={phoneForRegister} required />
                  </label>
                  <button type="submit" className="submit">Sign Up</button>
                  <label className={viewportSize > 600
            ? "d-none":""}>Already have an account?<br/> <span onClick={handleButtonClick} style={{
              color:"#6ECDE9"
                  }}>Login here</span></label>
                </form> */}
              </div>
          </div>

        </div>
  
  );
}



const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebar: state.data.sidebar,
    auth: state.data.auth,
    user_id: state.data.user_id,
    user_role: state.data.user_role,
    city_id: state.data.city_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setSideBar: (data) => dispatch(setSideBar(data)),
    setUserID: (id, role) => dispatch(setUserID(id, role)),
    setCityID: (id) => dispatch(setCityID(id)),
    setAuth: (auth) => dispatch(setAuth(auth)),
    logMeIn: () => dispatch(logMeIn()),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Register);
